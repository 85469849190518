import React from 'react';
import { FormTitle } from '../formTitle/FormTitle';
import { useLocation } from "react-router-dom";

export function Error() {
    const location = useLocation();
    const error = location.state?.error;
    const queryParams = new URLSearchParams(location.search);

    const errorTitle = queryParams.get('error');
    const errorDescription = queryParams.get('error_description');

    let errorInfo;
    if (errorTitle && errorDescription) {
        errorInfo = <form className="i-Form">
            <FormTitle text={errorTitle} />
            <div className="i-Form-info">
                <p className="i-Form-p">{errorDescription}</p>
            </div>
        </form>
    } else {
        errorInfo = <form className="i-Form">
            <FormTitle text="An error has occured" />
            <div className="i-Form-info">
                <p className="i-Form-p">{error}</p>
            </div>
        </form>
    }

    return (
        <section className="i-FormSection">
            {errorInfo}
        </section>
    )
}
