import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import "./FormMessage.scss";
import { DsRailAlert } from "snow-react-ds";

const formatErrors = (error) => {
    try {
        let errorsList = [];

        const parsedError = JSON.parse(error);
        const errors = parsedError["errors"] || {};

        Object.keys(errors)
            .forEach(key => errorsList.push(errors[key]));

        if (!errorsList) {
            throw new Error();
        }

        const errorItems = [].concat(...errorsList)
            .map((item, idx) => <Fragment key={idx}>{item}<br /></Fragment>);

        return errorItems;

    } catch (e) {
        return error;
    }
};

const alertVariants = {
    success: 'success',
    critical: 'critical',
    warning: 'warning'
}

const getAlertVariant = (error) => {
    try {
        const parsedError = JSON.parse(error);
        const severity = parsedError["severity"] || alertVariants.critical;
        const alertVariant = alertVariants[severity] || alertVariants.critical;

        return alertVariant;
    } catch (e) {
        return '';
    }
}

export function FormMessage(props) {
    let alertVariant = props.valid
        ? alertVariants.success
        : alertVariants.critical;

    const overrideVariant = getAlertVariant(props.article);
    if (overrideVariant) {
        alertVariant = overrideVariant
    }

    const content = props.valid ? props.article : formatErrors(props.article);
    const dsClasses = props.columnDirection ? "i-Alert-Column" : "";
    return (
        <DsRailAlert
            dsVariant={alertVariant}
            dsVisible={true}
            dsContent={content}
            dsClass={dsClasses}
        />
    )
}

FormMessage.propTypes = {
    name: PropTypes.string,
    article: PropTypes.string,
    hidden: PropTypes.bool,
    valid: PropTypes.bool,
    columnDirection: PropTypes.bool
};