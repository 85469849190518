import React, { Component } from 'react';
import "./PageIllustration.scss";

const reqIllustration = require.context('../../assets/images/illustrations/', true, /\.svg$/);

export class PageIllustration extends Component {
	static displayName = PageIllustration.name;

	render() {
		return (
			<div className="i-ImgSection i-PageSide-right">
				<div className="i-SideImg" style={{ backgroundImage: `url(${reqIllustration('./new-login.svg')})` }}>
					<svg style={{ width: "300px"}}>
						<use xlinkHref={`${reqIllustration(`./snow-logo-new.svg`)}#illustration`} />
					</svg>
				</div>
			</div>
		)
	}
}