import React from 'react';
import PropTypes from 'prop-types';
import "./FormTitle.scss";

export function FormTitle(props) {
	return (
		<section>
			<div className="i-Form_Text">{props.text}</div>
			{props.description && <div className="i-Form_Description">{props.description}</div>}
		</section>
	);
}

FormTitle.propTypes = {
	text: PropTypes.string,
	description: PropTypes.string,
};