import React, { useState } from 'react';
import "./RequestReset.scss";
import { FormTitle } from "../formTitle/FormTitle";
import { FormIconAndLink } from "../formIconAndLink/FormIconAndLink";
import * as api from '../../api';
import { DsButton, DsForm, dsFormObject, DsText } from "snow-react-ds";
import { FormMessage } from '../formMessage/FormMessage';
import { useLocation } from "react-router-dom";

export function RequestReset() {
    const location = useLocation();
    const regionHostname = location.state?.regionHostname;
    const email = location.state?.email;
    const slug = location.state?.slug;
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [requestInFlight, setRequestInFlight] = useState(false);

    const dsFormMethods = dsFormObject.useForm({
        defaultValues: {
            email: email,
        }
    });

    const { handleSubmit } = dsFormMethods;

    const onSubmit = async () => {
        if (requestInFlight) {
            return;
        }

        try {
            setRequestInFlight(true);
            let response = await api.requestReset(email, slug, regionHostname);

            if (!response.ok) {
                let err = await response.text()
                setError(err);
                return;
            }

            setSuccess('We have sent a password reset link to your email address if the account exists.');
            setError('');
        } catch (e) {
            console.log(e);
            setError('An error occured.');
        } finally {
            setRequestInFlight(false);
        }
    }

    return (
        <section className="i-FormSection">
            <div className="i-Form">
                <DsForm dsFormMethods={dsFormMethods}>
                    <div className="i-ItemForm">
                        <FormTitle text="Forgot your password?"
                            description="If you need to restore access to your account, we will send you a password reset link to your email address." />
                        <div className="i-Form-info">
                            {error &&
                                <FormMessage valid={false} name="errorMessage" article={error} />
                            }
                            {success &&
                                <FormMessage valid={true} name="successMessage" article={success} />
                            }
                            <DsText
                                disabled={true}
                                dsLabel="Email address"
                                dsType="text"
                                dsName="email"
                                dsValue={email}
                                dsAutocomplete="email"
                                dsClass='i-Form_Element-marginTop' />
                            <div className="i-Form_ButtonGroup">
                                <FormIconAndLink name="corner-up-left" text="Go back"
                                    pathname="/app/signinmultiplexer"
                                    search={location.search} />
                                <DsButton
                                    dsClass={`i-Button-element i-Form_Button-reset ${requestInFlight ? 'is-Spinning' : ''}`}
                                    disabled={success !== ''}
                                    dsSpinnerVisible={requestInFlight}
                                    dsVariant="primary"
                                    dsType="submit"
                                    dsOnClick={handleSubmit(onSubmit)}>
                                    Reset password
                                </DsButton>
                            </div>
                        </div>
                    </div>
                </DsForm>
            </div>
        </section>
    );
}
