import React, { useState } from 'react';
import * as querystring from "query-string";
import {FormTitle} from '../formTitle/FormTitle';
import * as api from '../../api';
import { Navigate, useLocation } from "react-router-dom";
import { DsButton, DsCheckbox, DsForm, dsFormObject } from "snow-react-ds";
import { FormMessage } from '../formMessage/FormMessage';

export function ClickThrough() {
    const location = useLocation();
    const regionHostName = location.state?.regionHostname || "";

    const returnUrl = querystring.parse(window.location.search).ReturnUrl;

    const [requestInFlight, setRequestInFlight] = useState(false);
    const [error, setError] = useState('')

    const [agreementCheck, setAgreementCheck] = useState(false)

    const agreementLabel = <>I agree to the&nbsp;<a href="https://www.flexera.com/legal/clickthrough" target='_blank' rel="noreferrer">Clickthrough Customer Agreement</a></>

    const dsFormMethods = dsFormObject.useForm({
    });
    const { handleSubmit, register } = dsFormMethods;
    const onSubmit = async (data) => {
        if (requestInFlight) {
            return;
        }

        try {
            setRequestInFlight(true);

            let response = await api.acceptClickThrough(regionHostName);

            if (!response.ok) {
                setError(await response.text());
                setRequestInFlight(false);
                return;
            }
            api.windowRedirect(regionHostName, returnUrl);
        } catch (e) {
            console.log(e);
            setError("An error occured.");
            setRequestInFlight(false);
        }
    }

    if (returnUrl === undefined || returnUrl === '') {
        return <Navigate to="/app/error"
            state={{ error: 'You may not visit the clickthrough page directly without being referred from another application' }} />
    }

    return (
        <section className="i-FormSection">
            <form className="i-Centered-Form">
                <DsForm dsFormMethods={dsFormMethods}>
                    <div>
                        <FormTitle text="Customer Agreement"/>
                        <div className="i-Form-info">
                            {error &&
                                <FormMessage error={error !== ''} valid={false} name="error-Icon" article={error} />
                            }
                            <p>To continue, review and agree to the relevant clickthrough agreement, and then select "I accept"</p>
                            <DsCheckbox
                                dsDescription={agreementLabel}
                                dsOnChange={setAgreementCheck}
                                dsChecked={agreementCheck}
                                dsFormRef={[
                                    dsFormObject,
                                    register('agreementCheck', {
                                    required: { value: true, message: 'Accepting terms and conditions is required' }
                                    })
                                ]} />
                            <p>By selecting the "I agree..." option, you agree to be bound by the terms of the Customer Agreement. If you or your company have a separately executed written agreement with Flexera for the product(s), then that separate agreement will apply</p>
                            <div className="i-Centered-Form_ButtonGroup">
                                <DsButton dsSpinnerVisible={requestInFlight} dsClass="i-Button-element"
                                    disabled={!agreementCheck}
                                    dsVariant="primary" dsType="submit"
                                    dsOnClick={handleSubmit(onSubmit)}>
                                    Accept
                                </DsButton>
                            </div>
                        </div>
                    </div>
                </DsForm>
            </form>
        </section>
    )
}
