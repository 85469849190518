import React from 'react';
import PropTypes from 'prop-types';
import "./FormLink.scss";
import {Link} from "react-router-dom";

export function FormLink(props) {
    return (
        <div className="i-Form_Link">
            <Link to={`${props.pathname}${props.search}`}
                  state={props.state}
            >{props.text}</Link>
        </div>
    )
}

FormLink.propTypes = {
    text: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
};