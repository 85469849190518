import React from 'react';
import PropTypes from 'prop-types';
import "./FormIconAndLink.scss";
import {Link} from "react-router-dom";
import {DsIcon} from 'snow-react-ds';

export function FormIconAndLink(props) {
    return (
        <div className="i-Form_LinkAndIcon">
            <DsIcon
                dsClass="i-Form_LinkAndIcon-icon"
                dsSize={10}
                dsVariant="generic_chevron-left"
            ></DsIcon>
            <div className="i-Form_LinkAndIcon-link">
                <Link to={`${props.pathname}${props.search}`}
                      state={{
                          skipCookies: true
                      }}
                >{props.text}</Link>
            </div>
        </div>
    )
}

FormIconAndLink.propTypes = {
    text: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string
};