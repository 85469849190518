import React  from 'react';

export function Layout (props) {
    return (
      <div className="idp-App">
        <div className="i-Container">
          {props.children}
        </div>
      </div>
    );
}
