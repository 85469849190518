import React, { useState } from 'react';
import * as querystring from "query-string";
import "./EmailFactor.scss";
import { FormTitle } from '../formTitle/FormTitle';
import { Navigate } from "react-router-dom";
import * as api from '../../api';
import { DsButton, DsForm, dsFormObject, DsText } from "snow-react-ds";
import { FormMessage } from '../formMessage/FormMessage';

export function EmailFactor() {
    const returnUrl = querystring.parse(window.location.search).ReturnUrl;

    const [requestInFlight, setRequestInFlight] = useState(false);
    const [resendCodeInFlight, setResendCodeInFlight] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const dsFormMethods = dsFormObject.useForm({
        defaultValues: {
            code: '',
        }
    });

    const { register, handleSubmit } = dsFormMethods;
    const onSubmit = async (data) => {
        if (requestInFlight) {
            return;
        }

        try {
            setRequestInFlight(true);

            let response = await api.submitEmailMfaCode(data.code, returnUrl);

            if (!response.ok) {
                setError("The verification code entered is invalid.");
                setRequestInFlight(false);
                return;
            }

            // url validated serverside
            window.location.href = returnUrl;
        } catch (e) {
            console.log(e);
            setError("An error occured.");
            setRequestInFlight(false);
        }
    }

    if (returnUrl === undefined || returnUrl === '') {
        return <Navigate to="/app/error"
            state={{ error: 'You may not visit this page directly without being referred from another application' }} />
    }

    const handleResend = async () => {
        if (resendCodeInFlight) {
            return;
        }

        try {
            setResendCodeInFlight(true);

            let response = await api.requestEmailMfaCode();

            if (!response.ok) {
                setError(await response.text());
                setResendCodeInFlight(false);
            }

            setSuccess('Verification code resent. Check your mailbox.');
            setError('');
        } catch (e) {
            console.log(e);
            setError("An error occured");
        }

        setResendCodeInFlight(false);
    }

    return (
        <section className="i-FormSection">
            <div className="i-Form">
                <DsForm dsFormMethods={dsFormMethods}>
                    <div className="i-ItemForm">
                        <FormTitle text="Confirm access" />
                        {error &&
                            <FormMessage error={error !== ''} valid={false} name="error-Icon"
                                article={error} />
                        }
                        {success &&
                            <FormMessage valid={true} name="successMessage" article={success} columnDirection={true} />
                        }
                        <p>Check your email and enter the verification code to authenticate.</p>
                        <div className="i-Form-info">
                            <div className="i-Form_Element-marginTop">
                                <DsText
                                    dsLabel="Verification code"
                                    dsType="password"
                                    dsName="code"
                                    dsFormRef={[
                                        dsFormObject,
                                        register('code', {
                                            required: { value: true, message: 'Required' }
                                        })
                                    ]}
                                    dsAutocomplete="off" />
                            </div>
                            <div className="i-Form_ButtonGroup">
                                <DsButton dsSpinnerVisible={resendCodeInFlight} dsClass="i-Button-element"
                                    dsVariant="primary" dsType="button"
                                    dsOnlyText={true}
                                    dsOnClick={() => handleResend()}>
                                    Resend verification code
                                </DsButton>
                                <DsButton dsSpinnerVisible={requestInFlight} dsClass="i-Button-element"
                                    dsVariant="primary" dsType="submit"
                                    dsOnClick={handleSubmit(onSubmit)}>
                                    Sign in
                                </DsButton>
                            </div>
                        </div>
                    </div>
                </DsForm>
            </div>
        </section>
    )
}
