import React  from 'react';
import { Layout } from './Layout';
import { Navbar } from './navbar/Navbar';
import { PolicyFooter } from './policy/PolicyFooter';
import { PageIllustration } from './pageIllustration/PageIllustration';

export function SplitLayout (props) {
	return (
		<Layout>
		  <div className="i-PageContent">
			<div className="i-PageSide-left">
			  <div className="i-PageSide-leftContent">
				<Navbar />
				{props.children}
			  </div>
			  <div className="i-PageSide-leftFooter">
				<PolicyFooter />
			  </div>
			</div>
			<PageIllustration />
		  </div>
		</Layout>
	  );
}
