import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SignInMultiplexer } from './components/signInMultiplexer/SignInMultiplexer';
import { Reset } from "./components/reset/Reset";
import { Error } from "./components/error/Error";
import { RequestReset } from "./components/requestreset/RequestReset";
import { SignIn } from "./components/signin/SignIn";
import { SignInDomain } from "./components/signInDomain/SignInDomain";
import { NotFound } from "./components/notfound/NotFound";
import {EmailFactor} from "./components/emailfactor/EmailFactor";
import {SelectFactor} from "./components/selectfactor/SelectFactor";
import { ClickThrough } from "./components/clickthrough/ClickThrough";
import { SplitLayout } from './components/SplitLayout';
import { CenteredLayout } from './components/CenteredLayout';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Routes>
        <Route path='/app/signinmultiplexer' element={<SplitLayout><SignInMultiplexer /></SplitLayout>} />
        <Route path='/app/reset' element={<SplitLayout><Reset /></SplitLayout>} />
        <Route path='/app/requestreset' element={<SplitLayout><RequestReset /></SplitLayout>} />
        <Route path='/app/error' element={<SplitLayout><Error /></SplitLayout>} />
        <Route path='/app/signin' element={<SplitLayout><SignIn /></SplitLayout>} />
        <Route path='/app/signindomain' element={<SplitLayout><SignInDomain /></SplitLayout>} />
        <Route path='/app/factors/select' element={<SplitLayout><SelectFactor /></SplitLayout>} />
        <Route path='/app/factors/email' element={<SplitLayout><EmailFactor /></SplitLayout>} />
        <Route path="/app/clickthrough" element={<CenteredLayout><ClickThrough /></CenteredLayout>} />
        <Route path="*" element={<SplitLayout><NotFound /></SplitLayout>} />
      </Routes>
    )
  }
}
