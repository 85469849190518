import React, { useRef, useState, useEffect } from 'react';
import * as querystring from "query-string";
import "./SignInDomain.scss";
import { FormTitle } from '../formTitle/FormTitle';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as api from '../../api';
import { FormIconAndLink } from "../formIconAndLink/FormIconAndLink";
import { DsButton, DsForm, DsText, DsCombobox, dsFormObject } from "snow-react-ds";
import { FormMessage } from '../formMessage/FormMessage';

export function SignInDomain() {
    const location = useLocation();
    const navigate = useNavigate();
    const domains = location.state?.domains;
    const email = location.state?.email;
    const returnUrl = querystring.parse(window.location.search).ReturnUrl;
    const submitRef = useRef();

    let domainOptions = [];

    if (!!domains) {
        domains.forEach(domain =>
            domainOptions.push({ "title": domain })
        );
    }

    let requestedSlugIndex = querystring.parse(window.location.search).select_slug;

    let starterSlug = '';

    if (domainOptions.length > 0 && requestedSlugIndex === undefined) {
        starterSlug = domainOptions[0].title;
    } else if (domainOptions.length > 0 && requestedSlugIndex !== undefined) {
        starterSlug = domainOptions[requestedSlugIndex].title;
    }

    const [error, setError] = useState('');
    const [requestInFlight, setRequestInFlight] = useState(false);

    const dsFormMethods = dsFormObject.useForm({
        defaultValues: {
            email: email,
            slug: starterSlug,
            selectedSlugIndex: requestedSlugIndex === undefined ? 0 : requestedSlugIndex
        }
    });

    const { register, handleSubmit } = dsFormMethods;

    const onSubmit = async (data) => {
        if (requestInFlight) {
            return;
        }
        try {
            if (!data.slug) {
                setError("The Domain field is required");
                setRequestInFlight(false);
                return;
            }

            setRequestInFlight(true);

            let response = await api.signInMultiplexer(data.email, data.slug);

            if (!response.ok) {
                let err = await response.text()
                setError(err);
                setRequestInFlight(false);
                return;
            }

            const dto = await response.json();

            if (dto.scheme === 'idp') {
                navigate(`/app/signin${window.location.search}`, {
                    state: {
                        email: data.email,
                        regionHostname: dto.regionHostname,
                        slug: data.slug
                    }
                });
            } else {
                await api.signInExternal(dto.scheme, dto.regionHostname, returnUrl, data.email, data.slug);
            }
        } catch (e) {
            setError("An error occured.");
            setRequestInFlight(false);
        }
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                submitRef.current?.click();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    if (!returnUrl) {
        return <Navigate to="/app/error"
            state={{ error: 'You may not visit the login page directly without being referred from another application' }} />
    }

    return (
        <section className="i-FormSection">
            <div className="i-Form">
                <DsForm dsFormMethods={dsFormMethods}>
                    <div className="i-ItemForm">
                        <FormTitle text="Welcome to Snow" description="Sign in to your account" />
                        <div className="i-Form-info">
                            {error &&
                                <FormMessage valid={false} name="errorMessage" article={error} />
                            }
                            <DsText
                                disabled={true}
                                dsLabel="Email address"
                                dsType="text"
                                dsName="email"
                                dsFormRef={[
                                    dsFormObject,
                                    register('email', {
                                        required: true
                                    })
                                ]}
                                dsAutocomplete="email"
                                dsClass='i-Form_Element-marginTop' />
                            <div className="i-Form_Element-marginTop">
                                <DsCombobox
                                    dsDisableSpellchecking={true}
                                    dsLabel="Domain"
                                    dsFormRef={[
                                        dsFormObject,
                                        register('slug', {
                                            required: true
                                        })
                                    ]}
                                    dsType="text"
                                    dsName="domainsList"
                                    dsItems={domainOptions}
                                    dsPlaceholder={domains?.length > 0 ? null : "The domain is provided in your invitation email. If lost, please contact your Snow Atlas system administrator"}
                                    dsAutocomplete="off"
                                />
                            </div>
                            <div className="i-Form_ButtonGroup">
                                <FormIconAndLink name="corner-up-left" text="Go back"
                                    pathname="/app/signinmultiplexer"
                                    search={location.search} />
                                <DsButton dsRef={submitRef} dsSpinnerVisible={requestInFlight}
                                    dsClass="i-Button-element" dsVariant="primary" dsType="submit"
                                    dsOnClick={handleSubmit(onSubmit)}>
                                    Continue
                                </DsButton>
                            </div>
                        </div>
                    </div>
                </DsForm>
            </div>
        </section>
    );
}
