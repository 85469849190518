
const reqIllustration = require.context('../assets/images/illustrations/', true, /\.svg$/);

export function CenteredLayout (props) {
	return (
		<div className="i-CenteredPageContent" style={{ backgroundImage: `url(${reqIllustration('./new-login.svg')})` }}>
			{props.children}
		</div>
	);
}
