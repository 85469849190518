import React, { useState } from 'react';
import * as querystring from "query-string";
import "./SignIn.scss";
import { FormTitle } from '../formTitle/FormTitle';
import { FormLink } from '../formLink/FormLink';
import { Navigate, useLocation } from "react-router-dom";
import { FormIconAndLink } from "../formIconAndLink/FormIconAndLink";
import * as api from '../../api';
import { DsButton, DsForm, dsFormObject, DsText } from "snow-react-ds";
import { FormMessage } from '../formMessage/FormMessage';

export function SignIn() {
    const location = useLocation();
    const email = location.state?.email;
    const slug = location.state?.slug;
    const regionHostName = location.state?.regionHostname;
    const returnUrl = querystring.parse(window.location.search).ReturnUrl;
    const search = location.search;

    const [requestInFlight, setRequestInFlight] = useState(false);
    const [error, setError] = useState('')

    const dsFormMethods = dsFormObject.useForm({
        defaultValues: {
            password: '',
        }
    });

    const { register, handleSubmit } = dsFormMethods;
    const onSubmit = async (data) => {
        if (requestInFlight) {
            return;
        }

        try {
            setRequestInFlight(true);

            let response = await api.signIn(email, slug, data.password, regionHostName, returnUrl);

            if (!response.ok) {
                setError(await response.text());
                setRequestInFlight(false);
                return;
            }

            const dto = await response.json();
            api.windowRedirect(regionHostName, dto.url);
        } catch (e) {
            console.log(e);
            setError("An error occured.");
            setRequestInFlight(false);
        }
    }

    if (returnUrl === undefined || returnUrl === '') {
        return <Navigate to="/app/error"
            state={{ error: 'You may not visit the login page directly without being referred from another application' }} />
    }

    return (
        <section className="i-FormSection">
            <div className="i-Form">
                <DsForm dsFormMethods={dsFormMethods}>
                    <div className="i-ItemForm">
                        <FormTitle text="Welcome to Snow" description="Sign in to your account" />
                        <div className="i-Form-info">
                            {error &&
                                <FormMessage error={error !== ''} valid={false} name="error-Icon" article={error} />
                            }
                            <div className="i-Form_Element-marginTop">
                                <DsText
                                    disabled={true}
                                    dsValue={email}
                                    dsLabel="Email address"
                                    dsType="text"
                                    dsName="email"
                                    dsAutocomplete="email" />
                            </div>
                            {slug &&
                                <div className="i-Form_Element-marginTop">
                                    <DsText
                                        dsLabel="Domain"
                                        dsValue={slug}
                                        disabled={true}
                                        dsType="text"
                                        dsName="slug"
                                        dsAutocomplete="domain" />
                                </div>
                            }
                            <div className="i-Form_Element-marginTop">
                                <DsText
                                    dsLabel="Password"
                                    dsType="password"
                                    dsName="password"
                                    dsFormRef={[
                                        dsFormObject,
                                        register('password', {
                                            required: true
                                        })
                                    ]}
                                    dsAutocomplete="current-password" />
                            </div>
                            <FormLink text="Forgot password?" pathname="/app/requestreset"
                                      search={search}
                                      state={{email: email, regionHostname: regionHostName, slug: slug}}/>
                            <div className="i-Form_ButtonGroup">
                                <FormIconAndLink name="corner-up-left" text="Go back" pathname="/app/signinmultiplexer"
                                    search={search} />
                                <DsButton dsSpinnerVisible={requestInFlight} dsClass="i-Button-element"
                                    dsVariant="primary" dsType="submit"
                                    dsOnClick={handleSubmit(onSubmit)}>
                                    Sign in
                                </DsButton>
                            </div>
                        </div>
                    </div>
                </DsForm>
            </div>
        </section>
    )
}
