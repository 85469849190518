import React from 'react';
import {FormTitle} from '../formTitle/FormTitle';

export function NotFound() {
    return (
        <section className="i-FormSection">
            <form className="i-Form">
                <FormTitle text="Page not found"/>
                <div className="i-Form-info">
                    <p>Oh no! Page not found, sorry about that</p>
                </div>
            </form>
        </section>
    )
}
