import React, { useState } from 'react';
import "./Reset.scss";
import { FormTitle } from '../formTitle/FormTitle';
import * as querystring from "query-string";
import * as api from '../../api';
import { DsButton, DsForm, DsText, DsRailAlert, dsFormObject } from "snow-react-ds";
import { FormMessage } from '../formMessage/FormMessage';

export function Reset() {

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [requestInFlight, setRequestInFlight] = useState(false);

    const email = querystring.parse(window.location.search).email;
    const code = querystring.parse(window.location.search).code;

    const dsFormMethods = dsFormObject.useForm({
        defaultValues: {
            password: '',
            confirmPassword: '',
        }
    });

    const { register, handleSubmit } = dsFormMethods;

    const getPortalUrl = () => {
        if (window.location.hostname === 'localhost') {
            return window.location.protocol + '//' + window.location.hostname;
        }

        let index = window.location.hostname.indexOf('.');
        let domain = window.location.hostname.substring(index + 1);
        return window.location.protocol + '//www.' + domain;
    }

    const onSubmit = async (data) => {
        if (requestInFlight) {
            return;
        }

        try {
            if (success !== '') {
                return;
            }

            setRequestInFlight(true);

            let response = await api.reset(email, data.password, data.confirmPassword, code);

            if (!response.ok) {
                let err = await response.text()
                setError(err);
                return;
            }

            setSuccess('Your password has successfully been reset.');
            setError('');
        } catch (e) {
            console.log(e);
            setError('An error occured.');
        } finally {
            setRequestInFlight(false);
        }
    }

    return (
        <section className="i-FormSection">
            <div className="i-Form">
                <DsForm dsFormMethods={dsFormMethods}>
                    <div className="i-ItemForm">
                        <div className="i-Form_Text-noSub">
                            <FormTitle text="Reset your password" />
                        </div>
                        <div className="i-Form-info">
                            <DsRailAlert
                                dsClass="i-Alert"
                                dsVariant="information"
                                dsVisible={true}
                                dsContent="Passwords must be 8 characters or more
                                and contain at least one digit, an uppercase,
                                lowercase and one special character."/>
                            {error &&
                                <FormMessage valid={false} name="errorMessage" article={error} />
                            }
                            {success &&
                                <FormMessage valid={true} name="successMessage" article={success} />
                            }
                            <DsText
                                disabled={true}
                                dsLabel="Email address"
                                dsType="text"
                                dsName="email"
                                dsValue={email}
                                dsAutocomplete="email"
                                dsClass='i-Form_Element-marginTop' />
                            <div className="i-Form_Element-marginTop">
                                <DsText
                                    dsLabel="Enter new password"
                                    dsType="password"
                                    dsName="password"
                                    dsFormRef={[
                                        dsFormObject,
                                        register('password', {
                                            required: true
                                        })
                                    ]}
                                    dsAutocomplete="new-password" />
                            </div>
                            <div className="i-Form_Element-marginTop">
                                <DsText
                                    dsLabel="Re-enter new password"
                                    dsType="password"
                                    dsName="confirmPassword"
                                    dsFormRef={[
                                        dsFormObject,
                                        register('confirmPassword', {
                                            required: true
                                        })
                                    ]}
                                    dsAutocomplete="new-password" />
                            </div>
                            {success !== '' &&
                                <div className="i-Form_LinkButton">
                                    <a href={getPortalUrl()}>Return to sign in</a>
                                </div>
                            }
                            <DsButton
                                dsClass={`i-Button-element i-Button-elementAlign i-Form_Button-reset ${requestInFlight ? 'is-Spinning' : ''}`}
                                disabled={success !== ''}
                                dsSpinnerVisible={requestInFlight}
                                dsVariant="primary"
                                dsType="submit"
                                dsOnClick={handleSubmit(onSubmit)}>
                                Reset password
                            </DsButton>
                        </div>
                    </div>
                </DsForm>
            </div>
        </section>
    )
}
