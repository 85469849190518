import React, { useState } from 'react';
import * as querystring from "query-string";
import "./SelectFactor.scss";
import { FormTitle } from '../formTitle/FormTitle';
import { Navigate, useNavigate } from "react-router-dom";
import * as api from '../../api';
import { DsButton, DsForm, dsFormObject } from "snow-react-ds";
import { FormMessage } from '../formMessage/FormMessage';

export function SelectFactor() {
    const navigate = useNavigate();
    const returnUrl = querystring.parse(window.location.search).ReturnUrl;

    const [requestInFlight, setRequestInFlight] = useState(false);
    const [error, setError] = useState('');

    const dsFormMethods = dsFormObject.useForm({});

    const { handleSubmit } = dsFormMethods;
    const onSubmit = async (data) => {
        if (requestInFlight) {
            return;
        }

        try {
            setRequestInFlight(true);

            let response = await api.requestEmailMfaCode();

            if (!response.ok) {
                let err = await response.text()
                setError(err);
                setRequestInFlight(false);
                return;
            }

            navigate(`/app/factors/email${window.location.search}`, {
                state: {
                }
            });
        } catch (e) {
            console.log(e);
            setError("An error occured.");
            setRequestInFlight(false);
        }
    }

    if (returnUrl === undefined || returnUrl === '') {
        return <Navigate to="/app/error"
            state={{ error: 'You may not visit the step up page directly without being referred from another application' }} />
    }

    return (
        <section className="i-FormSection">
            <div className="i-Form">
                <DsForm dsFormMethods={dsFormMethods}>
                    <div className="i-ItemForm">
                        <FormTitle text="Confirm access" />
                        <div className="i-Form-info">
                            {error &&
                                <FormMessage error={error !== ''} valid={false} name="error-Icon"
                                    article={error} />
                            }
                            <div className="i-Form_Element-marginTop">
                                <p>To proceed, you must authenticate with a verification code sent to you by email.</p>
                                <p>When you select <b>Continue</b>, an email with the code will be sent to your mailbox. This can take a few minutes.</p>
                            </div>
                            <div className="i-Form_ButtonGroup">
                                <DsButton dsSpinnerVisible={requestInFlight} dsClass="i-Button-element"
                                    dsIconRight={true}
                                    dsVariant="primary" dsType="submit"
                                    dsOnClick={handleSubmit(onSubmit)}>
                                    Continue
                                </DsButton>
                            </div>
                        </div>
                    </div>
                </DsForm>
            </div>
        </section>
    )
}
