import React, { useEffect, useState } from 'react';
import * as querystring from "query-string";
import "./SignInMultiplexer.scss";
import { FormTitle } from '../formTitle/FormTitle';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as api from '../../api';
import cookies from "js-cookie";
import { DsButton, DsForm, dsFormObject, DsText } from "snow-react-ds";
import { FormMessage } from '../formMessage/FormMessage';

export function SignInMultiplexer() {
    const location = useLocation();
    const navigate = useNavigate();
    const slug = ''; // always empty ?
    const returnUrl = querystring.parse(window.location.search).ReturnUrl;
    const skipCookies = location.state?.skipCookies || false;


    const [error, setError] = useState('');
    const [requestInFlight, setRequestInFlight] = useState(false);

    const dsFormMethods = dsFormObject.useForm({
        defaultValues: {
            email: '',
        }
    });

    const { register, handleSubmit, setValue } = dsFormMethods;

    const getCookieEmail = () => {
        return cookies.get('id.who');
    };

    const getCookieDomains = () => {
        let domainObject = cookies.get('id.who.domains');
        let domains;

        if (domainObject !== undefined) {
            domains = JSON.parse(domainObject);
        }

        return domains;
    };

    useEffect(() => {
        let cookieEmail = getCookieEmail();

        if (!skipCookies && cookieEmail !== undefined) {
            navigate(`/app/signindomain${window.location.search}`, {
                state: {
                    email: cookieEmail,
                    domains: getCookieDomains()
                }
            });
        }

        if (cookieEmail !== undefined) {
            setValue('email', cookieEmail);
        }
    }, [navigate, setValue, skipCookies]);

    const onSubmit = async (data) => {
        if (requestInFlight) {
            return;
        }

        try {
            setRequestInFlight(true);
            let response = await api.signInMultiplexer(data.email, slug);
            if (response.status === 409) {
                navigate(`/app/signindomain${window.location.search}`, {
                    state: {
                        email: data.email,
                        domains: data.email === getCookieEmail() ? getCookieDomains() : undefined
                    }
                });
                return;
            } else if (!response.ok) {
                let err = await response.text()
                setError(err);
                setRequestInFlight(false);
                return;
            }

            const dto = await response.json();

            if (dto.scheme === 'idp') {
                navigate(`/app/signin${window.location.search}`, {
                    state: {
                        email: data.email,
                        regionHostname: dto.regionHostname,
                        slug: slug
                    }
                });
            } else {
                await api.signInExternal(dto.scheme, dto.regionHostname, returnUrl, data.email, slug);
            }
        } catch (e) {
            setError("serAn error occured.");
            setRequestInFlight(false);
        }
    }

    if (!returnUrl) {
        return <Navigate to="/app/error"
            state={{ error: 'You may not visit the login page directly without being referred from another application' }} />
    }

    return (
        <section className="i-FormSection">
            <div className="i-Form">
                <DsForm dsFormMethods={dsFormMethods}>
                    <div className="i-ItemForm">
                        <FormTitle text="Welcome to Snow" description="Sign in to your account" />
                        <div className="i-Form-info">
                            {error &&
                                <FormMessage valid={false} name="errorMessage" article={error} />
                            }
                            <DsText
                                dsLabel="Email address"
                                dsType="text"
                                dsName="email"
                                dsFormRef={[
                                    dsFormObject,
                                    register('email', {
                                        required: true,
                                        pattern: {
                                            value:
                                                /\S+@\S+\.\S+/,
                                            message: 'The Email format is incorrect.'
                                        }
                                    })
                                ]}
                                dsAutocomplete="email" />
                            <DsButton disabled={requestInFlight}
                                dsSpinnerVisible={requestInFlight} dsClass="i-Button-form"
                                dsVariant="primary" dsType="submit"
                                dsOnClick={handleSubmit(onSubmit)}>
                                Continue
                            </DsButton>
                        </div>
                    </div>
                </DsForm>
            </div>
        </section>
    );
}
